import React, { useEffect, useState, Component } from "react";
import { useParams, useNavigate, json } from "react-router-dom";
import { useDevices } from "../../contexts/AssetContext";
import axios from "axios";
import { SimpleCard } from "app/components";
import { styled, Button, Grid, TextField } from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Loading from "app/components/MatxLoading";

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(1)
}));

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const AssetEdit = () => {
  const [device, setDevice] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [editedDevice, setEditedDevice] = useState({ ...device });
  const { id } = useParams();
  const navigate = useNavigate();
  const { editDevice } = useDevices();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // Fetch the device list from your API
  const fetchDevice = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/devices/${id}`);
      if (response.status !== 200) throw new Error("Failed to fetch");
      const data = await response.data;
      setDevice(data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDevice();
  }, []);

  useEffect(() => {
    setEditedDevice({ ...device });
  }, [device]);

  useEffect(() => {
    // Check if `device.notes` exists and has content
    if (device && device.notes) {
      // Assuming `device.notes` is in Draft.js's Raw format
      try {
        const jsonData = JSON.parse(device.notes);
        const contentState = convertFromRaw(jsonData);
        setEditorState(EditorState.createWithContent(contentState));
      } catch (error) {
        console.error("Error converting notes to editor content:", error);
        // Handle error or set editor to empty state
      }
    }
  }, [device]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedDevice((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const jsonContent = JSON.stringify(rawContent);
    setEditedDevice((prev) => ({
      ...prev,
      notes: jsonContent
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await editDevice({ id: id, editedDevice });
      navigate(-1);
    } catch (error) {
      alert("Failed to add device");
    }
  };

  if (!device) return <Loading />;

  return (
    <ContentBox>
      <h2>Edit Asset</h2>
      <SimpleCard title={editedDevice.friendlyName}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label="Name"
                variant="outlined"
                value={editedDevice.friendlyName || ""}
                onChange={handleChange}
                name="friendlyName"
              />
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label="Hostname"
                variant="outlined"
                value={editedDevice.hostname || ""}
                onChange={handleChange}
                name="hostname"
              />
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label="Asset Tag"
                variant="outlined"
                value={editedDevice.assetTag || ""}
                onChange={handleChange}
                name="assetTag"
              />
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label="Serial"
                name="serial"
                variant="outlined"
                value={editedDevice.serial || ""}
                onChange={handleChange}
              />
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label="Location"
                variant="outlined"
                name="site"
                value={editedDevice.site || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label="Manufacturer"
                variant="outlined"
                name="manufacturer"
                value={editedDevice.manufacturer || ""}
                onChange={handleChange}
              />
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label="Model"
                name="modelNumber"
                variant="outlined"
                value={editedDevice.modelNumber || ""}
                onChange={handleChange}
              />
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label="OS Version"
                name="version"
                variant="outlined"
                value={editedDevice.version || ""}
                onChange={handleChange}
              />
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label="IP Address"
                name="ipAddress"
                variant="outlined"
                value={editedDevice.ipAddress || ""}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ padding: "10px" }}>
            <Editor
              wrapperClassName="editorWrapper"
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: "10px" }}>
            <Button variant="contained" color="primary" type="submit">
              Save Changes
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate(-1)}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </Grid>
        </form>
      </SimpleCard>
    </ContentBox>
  );
};

export default AssetEdit;
