import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDevices } from "../../contexts/AssetContext";

import {
  Box,
  Icon,
  Table,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  Button
} from "@mui/material";
import { SimpleCard } from "app/components";

// STYLED COMPONENT
const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } }
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0 } }
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0)
}));

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const Assets = () => {
  const { devices, isLoading, error, files, fetchDevices, deleteDevice } = useDevices();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDevices();
  }, []);

  const handleRowClick = (deviceId) => {
    navigate(`/assets/${deviceId}`);
  };

  // Imagine devices is an array of device objects fetched from an API
  return (
    <div className="">
      <ContentBox>
        <h2 style={{ display: "inline" }}>Assets</h2>
        <div className="separator" />
        <SimpleCard>
          <StyledButton variant="contained" color="primary" onClick={() => navigate("/assets/add")}>
            + Add Device
          </StyledButton>
          <StyledButton
            variant="contained"
            color="secondary"
            onClick={() => fetchDevices()}
            style={{ marginLeft: "10px" }}
          >
            Refresh
          </StyledButton>
          <Box width="100%" overflow="auto">
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell align="left">&#9741; Name</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Manufacturer</TableCell>
                  <TableCell align="left">Serial</TableCell>
                  <TableCell align="left">Model</TableCell>
                  <TableCell align="left">Version</TableCell>
                  <TableCell align="left">Location</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {devices.map((subscriber, index) => (
                  <TableRow key={index} onClick={() => handleRowClick(subscriber._id)}>
                    <TableCell align="left">
                      {subscriber.heartbeat ? <>&#9741; </> : ""}
                      {subscriber.friendlyName ? subscriber.friendlyName : subscriber.hostname}
                    </TableCell>
                    <TableCell align="left">{subscriber.manufacturer}</TableCell>
                    <TableCell align="left">{subscriber.serial}</TableCell>
                    <TableCell align="left">{subscriber.modelNumber}</TableCell>
                    <TableCell align="left">{subscriber.version}</TableCell>
                    <TableCell align="left">{subscriber.site}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          deleteDevice(subscriber._id);
                        }}
                      >
                        <Icon color="error">close</Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </Box>
        </SimpleCard>
      </ContentBox>
    </div>
  );
};

export default Assets;
