import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from "axios";

// Create a context
const AssetContext = createContext();

// Provider component
export const DeviceProvider = ({ children }) => {
  const [devices, setDevices] = useState([]);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchDevices();
  }, []); // Dependency array is empty, effect runs once on mount

  // Fetch the device list from your API
  const fetchDevices = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('/devices');
      if (response.status !== 200) throw new Error('Failed to fetch');
      const data = await response.data;
      setDevices(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Add a device
  const addDevice = async (newDevice) => {
    const data = JSON.stringify(newDevice);
    try {
      const response = await axios.post(`/devices`, data, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (response.status !== 200) throw new Error('Failed to fetch');
      // Optionally refetch or directly append to local state
      fetchDevices(); // or setDevices([...devices, newDevice]) if the backend returns the complete device object including its ID
    } catch (error) {
      setError(error.message);
    }
  };

  // Edit a device
  const editDevice = async (input) => {
    try {
      const response = await axios.put(`/devices/${input.id}`, JSON.stringify(input.editedDevice), {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (response.status !== 200) throw new Error('Failed to edit device');
      fetchDevices(); // Refresh the list to show the updated data
    } catch (error) {
      setError(error.message);
    }
  };

  // Edit a device
  const deleteDevice = async (input) => {
    try {
      const response = await axios.delete(`/devices/${input}`);
      if (response.status !== 200) throw new Error('Failed to update device');
      console.log(response.data)
      fetchDevices(); // Refresh the list to show the updated data
    } catch (error) {
      setError(error.message);
    }
  };

  // Get count of devices
  const getDeviceCount = () => {
    return devices.length;
  };

  // Get device files
  const getFiles = async (input) => {
    try {
      const response = await axios.get(`/files/list/${input}`);
      if (response.status !== 200) {
        throw new Error('Failed to edit device') && setFiles([]);
      }
      else {
        setFiles(response.data);
      }
      
    } catch (error) {
      setError(error.message);
      setFiles([])
    }
  };

  const deleteFile = async (id, fileName) => {
    try {
      const response = await axios.delete(`/files/delete`, {data: {id, fileName}});
      if (response.status !== 200) {
        alert(response.data.message)
      }
      else {
        alert(response.data.message);
        await getFiles(id);
      }
    }
    catch (err) {
      setError(err.message);
    }
  }

  return (
    <AssetContext.Provider value={{ devices, isLoading, error, files, fetchDevices, addDevice, editDevice, deleteDevice, getDeviceCount, getFiles, deleteFile }}>
      {children}
    </AssetContext.Provider>
  );
};

// Custom hook to use the device context
export const useDevices = () => useContext(AssetContext);
