import React, { useEffect } from "react";
import {
  Box,
  Icon,
  Table,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  Button
} from "@mui/material";
import { SimpleCard } from "app/components";
import { useDNS } from "../../contexts/DNSContext";

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

// STYLED COMPONENT
const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } }
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } }
  }
}));

const DNSList = () => {
  const { rdns, isLoading, error, fetchRDNSList } = useDNS();

  useEffect(() => {
    fetchRDNSList();
  }, []);

  return (
    <ContentBox>
      <SimpleCard>
        <h3>Locations</h3>
        <Box width="100%" overflow="auto">
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell align="left">IP</TableCell>
                <TableCell align="center">dnsRecordType</TableCell>
                <TableCell align="center">dnsEntry</TableCell>
                <TableCell align="center">Notes</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rdns.map((location, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{location.ipAddress}</TableCell>
                  <TableCell align="center">{location.dnsRecordType}</TableCell>
                  <TableCell align="center">{location.dnsEntry}</TableCell>
                  <TableCell align="center">{location.notes}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <Icon color="error">close</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </Box>
      </SimpleCard>
    </ContentBox>
  );
};

export default DNSList;
