import axios from 'axios';

const downloadFile = async (fileUrl, fileName) => {
  try {
    const response = await axios.get(fileUrl, {
      responseType: 'blob', // Important to process the file stream
    });

    // Create a URL for the blob
    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    
    // Create an anchor element and trigger the download
    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', fileName); // Set the file name
    document.body.appendChild(link);
    link.click();

    link.parentNode.removeChild(link); // Clean up
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

export default downloadFile;