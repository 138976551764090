import axios from 'axios';
import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a context
const LocationContext = createContext();

// Provider component
export const LocationProvider = ({ children }) => {
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchLocations = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/locations`);
      if (response.status !== 200) throw new Error('Failed to fetch');
      const data = response.data;
      setLocations(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <LocationContext.Provider value={{ locations, isLoading, error, fetchLocations }}>
      {children}
    </LocationContext.Provider>
  );
};

// Custom hook to use the device context
export const useLocations = () => useContext(LocationContext);
