import React, { useState } from 'react';
import axios from 'axios';
import { useDevices } from 'app/contexts/AssetContext';

function FileUpload({id}) {
  const { devices, isLoading, error, files, getFiles } = useDevices();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file first!');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      // Adjust the URL to your server's address and make sure to include the serial parameter in the URL
      const response = await axios.post(`/files/upload/${id.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Assuming your API responds with a message
      
      getFiles(id.id);
      alert(response.data.message);
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file');
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload File</button>
    </div>
  );
}

export default FileUpload;