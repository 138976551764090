import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDevices } from "../../contexts/AssetContext";
import axios from "axios";
import { SimpleCard } from "app/components";
import { EditorState, convertFromRaw } from "draft-js";
import ReactTimeAgo from "react-time-ago";
import {
  Box,
  styled,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Button,
  Grid,
  IconButton,
  Icon
} from "@mui/material";
import downloadFile from "app/hooks/downloadFiles";
import FileUpload from "app/hooks/uploadFile";
import { Editor } from "react-draft-wysiwyg";
import Loading from "app/components/MatxLoading";

const StyledButton = styled(Button)(({ theme }) => ({}));

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } }
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } }
  }
}));

const AssetDetails = () => {
  const { devices, isLoading, error, files, getFiles, deleteFile } = useDevices();
  const [device, setDevice] = useState(null);
  const [devicesLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // Fetch the device list from your API
  const fetchDevice = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/devices/${id}`);
      if (response.status !== 200) throw new Error("Failed to fetch");
      const data = await response.data;
      setDevice(data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDevice();
    getFiles(id);
  }, []);

  useEffect(() => {
    // Check if `device.notes` exists and has content
    if (device && device.notes) {
      // Assuming `device.notes` is in Draft.js's Raw format
      try {
        const jsonData = JSON.parse(device.notes);
        const contentState = convertFromRaw(jsonData);
        setEditorState(EditorState.createWithContent(contentState));
      } catch (error) {
        console.error("Error converting notes to editor content:", error);
        // Handle error or set editor to empty state
      }
    }
  }, [device]);

  if (!device) return <Loading />;

  return (
    <ContentBox>
      <h2 onFocus={"Synced"} style={{ display: "inline", color: "green" }}>
        <b>{device.heartbeat ? <>&#9741; </> : ""}</b>
      </h2>
      <h2 style={{ display: "inline" }}>
        {device.friendlyName ? device.friendlyName : device.hostname}
      </h2>
      <p
        style={{
          display: "inline",
          background: "blue",
          padding: "8px",
          borderRadius: "10px",
          color: "white",
          marginLeft: "5px"
        }}
      >
        {" "}
        <b>&#183;</b> Active
      </p>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate(`/assets/edit/${id}`)}
        style={{ position: "absolute", right: "27px", display: "inline" }}
      >
        Edit Device
      </Button>
      <div className="separator" />
      <SimpleCard>
        <Grid container>
          <Grid item xs={12} md={6}>
            <p>
              <b>Name</b> - {device.friendlyName}
            </p>
            <p>
              <b>Hostname</b> - {device.hostname}
            </p>
            <p>
              <b>Manufacturer</b>- {device.manufacturer}
            </p>
            <p>
              <b>Model</b> - {device.modelNumber}
            </p>
            <p>
              <b>Serial</b> - {device.serial}
            </p>
            <p>
              <b>OS Version</b> - {device.version}
            </p>
            <p>
              <b>IP Address</b> - {device.ipAddress}{" "}
            </p>
            <p>
              <b>Location</b> - {device.site}
            </p>
            {device.heartbeat ? (
              <p>
                <b>Last Heartbeart</b> - <ReactTimeAgo date={device.heartbeat} locale="en-US" />
              </p>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </SimpleCard>
      <div className="separator" />
      <SimpleCard>
        <h3>Notes</h3>
        <Editor editorState={editorState} toolbarHidden readOnly={true} />
      </SimpleCard>
      <div className="separator" />
      <SimpleCard style={{ marginTop: "20px" }}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <h3>Files</h3>

            <FileUpload id={{ id: device._id }}></FileUpload>

            <Box width="100%" overflow="auto">
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">File Name</TableCell>
                    <TableCell align="left ">Open</TableCell>
                    <TableCell align="right ">Delete</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {files.map((subscriber, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">{subscriber.name}</TableCell>
                      <TableCell align="left">
                        <button onClick={() => downloadFile(subscriber.url, subscriber.name)}>
                          Download
                        </button>
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            deleteFile(device._id, subscriber.name);
                          }}
                        >
                          <Icon color="error">close</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </Box>
          </Grid>
        </Grid>
      </SimpleCard>
    </ContentBox>
  );
};

export default AssetDetails;
