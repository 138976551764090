import {
  Box,
  Icon,
  Table,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  Button,
  Grid,
  TextField
} from "@mui/material";
import { SimpleCard } from "app/components";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDevices } from "../../contexts/AssetContext";

// Styled Components
const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(1)
}));

const AddAsset = () => {
  const [editedDevice, setEditedDevice] = useState({});
  const navigate = useNavigate();
  const { addDevice } = useDevices();

  const startingState = {
    friendlyName: "",
    manufacturer: "",
    model: "",
    serial: ""
  };

  useEffect(() => {
    setEditedDevice(startingState);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedDevice((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDevice(editedDevice);
      navigate("/assets");
    } catch (error) {
      alert("Failed to add device");
    }
  };

  return (
    <ContentBox>
      <h2>Add Asset</h2>
      <SimpleCard title={editedDevice.friendlyName}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label="Name"
                variant="outlined"
                value={editedDevice.friendlyName || ""}
                onChange={handleChange}
                name="friendlyName"
              />
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label="Hostname"
                variant="outlined"
                value={editedDevice.hostname || ""}
                onChange={handleChange}
                name="hostname"
              />
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label="Asset Tag"
                variant="outlined"
                value={editedDevice.assetTag || ""}
                onChange={handleChange}
                name="assetTag"
              />
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label="Serial"
                name="serial"
                variant="outlined"
                value={editedDevice.serial || ""}
                onChange={handleChange}
              />
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label="Location"
                variant="outlined"
                name="location"
                value={editedDevice.location || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label="Manufacturer"
                variant="outlined"
                name="manufacturer"
                value={editedDevice.manufacturer || ""}
                onChange={handleChange}
              />
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label="Model"
                name="modelNumber"
                variant="outlined"
                value={editedDevice.modelNumber || ""}
                onChange={handleChange}
              />
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label="OS Version"
                name="version"
                variant="outlined"
                value={editedDevice.version || ""}
                onChange={handleChange}
              />
              <StyledTextField
                fullWidth
                id="outlined-basic"
                label="IP Address"
                name="ipAddress"
                variant="outlined"
                value={editedDevice.ipAddress || ""}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ padding: "10px" }}>
            <Button variant="contained" color="primary" type="submit">
              Save Changes
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate(-1)}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </Grid>
        </form>
      </SimpleCard>
    </ContentBox>
  );
};

export default AddAsset;
