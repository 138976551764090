import React, { useEffect } from "react";
import { useLocations } from "../../contexts/LocationContext";
import {
  Box,
  Icon,
  Table,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  Button
} from "@mui/material";
import { SimpleCard } from "app/components";

// STYLED COMPONENT
const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } }
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } }
  }
}));

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const Locations = () => {
  const { locations, isLoading, error, fetchLocations } = useLocations();

  useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <ContentBox>
      <SimpleCard>
        <h3>Locations</h3>
        <Box width="100%" overflow="auto">
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="center">Address</TableCell>
                <TableCell align="center">City</TableCell>
                <TableCell align="center">State</TableCell>
                <TableCell align="center">contactEmail</TableCell>
                <TableCell align="center">contactPhone</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {locations.map((location, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{location.name}</TableCell>
                  <TableCell align="center">{location.name}</TableCell>
                  <TableCell align="center">{location.name}</TableCell>
                  <TableCell align="center">{location.name}</TableCell>
                  <TableCell align="center">{location.name}</TableCell>
                  <TableCell align="center">{location.name}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <Icon color="error">close</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </Box>
      </SimpleCard>
    </ContentBox>
  );
};

export default Locations;
