import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import Assets from "./views/assets/Assets";
import AssetDetails from "./views/assets/AssetDetails";
import AddAsset from "./views/assets/addAsset";
import AssetEdit from "./views/assets/editAsset";
import Locations from "./views/locations/Locations";
import DNSList from "./views/dns/dnsList";


// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(lazy(() => import("app/views/sessions/JwtRegister")));
const ForgotPassword = Loadable(lazy(() => import("app/views/sessions/ForgotPassword")));
// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      // dashboard route
      { path: "/dashboard/default", element: <Analytics />, auth: authRoles.admin },
      { path: "/assets", element: <Assets />, auth: authRoles.admin },
      { path: "/assets/:id", element: <AssetDetails />, auth: authRoles.admin },
      { path: "/assets/edit/:id", element: <AssetEdit />, auth: authRoles.admin },
      { path: "/assets/add", element: <AddAsset />, auth: authRoles.admin },
      { path: "/locations", element: <Locations />, auth: authRoles.admin },
      { path: "/dns", element: <DNSList />, auth: authRoles.admin }
    ]
  },

  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signin", element: <JwtLogin /> },
  { path: "/session/signup", element: <JwtRegister /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },

  { path: "/", element: <Navigate to="dashboard/default" /> },
  { path: "*", element: <NotFound /> }
];

export default routes;
