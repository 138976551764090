import { useRoutes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import axios from "axios";

import { MatxTheme } from "./components";
import "./styles.css";

// ALL CONTEXTS
import { AuthProvider } from "./contexts/JWTAuthContext";
import SettingsProvider from "./contexts/SettingsContext";
// ROUTES
import routes from "./routes";
import { DeviceProvider } from "./contexts/AssetContext";
import { LocationProvider } from "./contexts/LocationContext";
import { DNSProvider } from "./contexts/DNSContext";

axios.defaults.baseURL = "https://api.totyl.app";
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default function App() {
  const content = useRoutes(routes);

  return (
    <SettingsProvider>
      <AuthProvider>
        <DeviceProvider>
          <LocationProvider>
            <DNSProvider>
              <MatxTheme>
                <CssBaseline />
                {content}
              </MatxTheme>
            </DNSProvider>
          </LocationProvider>
        </DeviceProvider>
      </AuthProvider>
    </SettingsProvider>
  );
}
