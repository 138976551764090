import axios from 'axios';
import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a context
const DNSContext = createContext();

// Provider component
export const DNSProvider = ({ children }) => {
  const [rdns, setRDNS] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchRDNSList();
  }, []); // Dependency array is empty, effect runs once on mount

  // Fetch the device list from your API
  const fetchRDNSList = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/rdns`);
      if (response.status !== 200) throw new Error('Failed to fetch');
      const data = await response.data
      setRDNS(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Add a device
  const addRDNSRecord = async (newDevice) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/rdns`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newDevice),
      });
      if (!response.ok) throw new Error('Failed to add device');
      // Optionally refetch or directly append to local state
      fetchRDNSList(); // or setRDNS([...rdns, newDevice]) if the backend returns the complete device object including its ID
    } catch (error) {
      setError(error.message);
    }
  };

  // Edit a device
  const editRDNSRecord = async (input) => {
    try {
      console.log(input.id)
      const response = await fetch(`${process.env.REACT_APP_API_URL}/rdns/${input.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(input.editedDevice),
      });
      if (!response.ok) throw new Error('Failed to update device');
      fetchRDNSList(); // Refresh the list to show the updated data
    } catch (error) {
      setError(error.message);
    }
  };

  // Get count of rdns
  const getRDNSListCount = () => {
    return rdns.length;
  };

  return (
    <DNSContext.Provider value={{ rdns, isLoading, error, addRDNSRecord, editRDNSRecord, getRDNSListCount, fetchRDNSList }}>
      {children}
    </DNSContext.Provider>
  );
};

// Custom hook to use the device context
export const useDNS = () => useContext(DNSContext);
